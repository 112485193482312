import { Injectable } from "@angular/core";
import { StoreService } from "..";
import { catchError, combineLatest, from, map, Observable, tap } from "rxjs";
import { SubriseUser, SubriseUserBase } from "../../models/users/subrise-user.model";
import { UserApiService } from "../../services/api/user-api.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private store: StoreService,
    private userApiService: UserApiService
  ) { }

  get currentUser$(): Observable<SubriseUser | null> {
    return this.store.state$.pipe(map(state => state.currentUser));
  }

  get users$(): Observable<SubriseUser[]> {
    return this.store.state$.pipe(map(state => Object.values(state.users)));
  }

  load(): Observable<{ currentUser: SubriseUser; users: SubriseUser[]; }> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return combineLatest([from(this.userApiService.get()), this.userApiService.getAll()]).pipe(
      map(([currentUser, users]) => ({ currentUser, users })),
      tap(({ currentUser, users }) => {
        const state = { ...this.store.state };
        users.forEach((user) => state.users[user.id] = user);
        state.currentUser = state.users[currentUser.id];
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error loading users:', error);
        throw error;
      })
    );
  }

  addUser(user: SubriseUserBase, password: string): Observable<SubriseUser> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.userApiService.add(user, password)).pipe(
      tap((user) => {
        const state = { ...this.store.state };
        state.users[user.id] = user;
        state.loading = false;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error adding user:', error);
        throw error;
      })
    );
  }

  updateUser(user: SubriseUser): Observable<SubriseUser> {
    this.store.setState({ ...this.store.state, loading: true, error: null });
    return from(this.userApiService.update(user)).pipe(
      tap((user) => {
        const state = { ...this.store.state };
        state.users[user.id] = user;
        state.currentUser = user.id === state.currentUser?.id ? user : state.currentUser;
        this.store.setState(state);
      }),
      catchError((error) => {
        this.store.setState({ ...this.store.state, loading: false, error });
        console.error('Error updating user:', error);
        throw error;
      })
    )
  }

  clear(): void {
    const state = { ...this.store.state };
    state.users = {};
    state.currentUser = null;
    this.store.setState(state);
  }
}