import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@beyou/environments/environment';
import { firstValueFrom, map, Observable } from 'rxjs';
import { UserDto } from '../../dto/users/user.dto';
import { SubriseUser, SubriseUserBase } from '../../models/users/subrise-user.model';
import { UserMapper } from '../../mappers/users/user.mapper';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends BaseApiService {

  constructor(http: HttpClient) {
    super(http);
  }

  public getAll(): Observable<SubriseUser[]> {
    const apiUrl = `${environment.adminApiBaseUrl}/users`;
    return this.getAllPagedData<UserDto>(apiUrl).pipe(
      map((response) => {
        return response.map(item => {
          return UserMapper.toModel(item);
        })
      })
    );
  }

  public async get(id?: string): Promise<SubriseUser> {
    const userId = id || 'me';
    const apiUrl = `${environment.adminApiBaseUrl}/users/${userId}`;
    return await firstValueFrom(this.http.get<UserDto>(apiUrl).pipe(
      map((response) => {
        console.debug('User retrieved: ', response.id);
        return UserMapper.toModel(response);
      })
    ));
  }

  public async update(user: SubriseUser): Promise<SubriseUser> {
    const apiUrl = `${environment.adminApiBaseUrl}/users/${user.id}`;
    const dto = UserMapper.toUpdateDto(user);
    return await firstValueFrom(this.http.put<UserDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('User updated: ', response.id);
        return UserMapper.toModel(response);
      })
    ));
  }

  public async add(user: SubriseUserBase, password: string): Promise<SubriseUser> {
    const apiUrl = `${environment.adminApiBaseUrl}/users`;
    const dto = UserMapper.toCreateDto(user,password);
    return await firstValueFrom(this.http.post<UserDto>(apiUrl, dto).pipe(
      map((response) => {
        console.debug('User added: ', response.id);
        return UserMapper.toModel(response);
      })
    ));
  }
}