import { UserCreateDto } from "../../dto/users/user-create.dto";
import { UserUpdateDto } from "../../dto/users/user-update.dto";
import { UserDto } from "../../dto/users/user.dto";
import { SubriseUser, SubriseUserBase } from "../../models/users/subrise-user.model";
import { toDateString } from "../../utils/datehelper";
import { LocaleMapper } from "../locale.mapper";

export class UserMapper {
  public static toModel(dto: UserDto): SubriseUser {
    return {
      id: dto.id,
      firstName: dto.first_name || '',
      lastName: dto.last_name || '',
      userName: dto.user_name || '',
      displayName: dto.display_name || '',
      email: dto.email || '',
      gender: dto.gender || '',
      isTest: dto.is_test || false,
      occupation: dto.occupation || '',
      birthday: dto.birthday ? new Date(dto.birthday) : null,
      companyName: dto.company_name || '',
      favoritePlace: dto.favorite_place || '',
      anchoringAction: dto.anchoring_action || '',
      anchoringWord: dto.anchoring_word || '',
      selfHypnosisAction: dto.self_hypnosis_action || '',
      locale: LocaleMapper.toModel(dto.locale),
      sessionIds: dto.session_ids || [],
      smartUserProgramIds: dto.smart_user_program_ids || [],
      roles: dto.roles || [],
    }
  }

  public static toUpdateDto(model: SubriseUser): UserUpdateDto {
    const dto = this.getUpdateDto(model);
    return dto;
  }

  public static toCreateDto(model: SubriseUserBase, password: string): UserCreateDto {
    const dto = this.getUpdateDto(model);
    return {
      ...dto,
      password: password,
      email: model.email,
    }
  }

  private static getUpdateDto(model: SubriseUser| SubriseUserBase): UserUpdateDto {
    return {
      first_name: model.firstName,
      last_name: model.lastName,
      display_name: model.displayName,
      gender: model.gender,
      occupation: model.occupation || undefined,
      birthday: model.birthday? toDateString(model.birthday) : undefined,
      company_name: model.companyName || undefined,
      favorite_place: model.favoritePlace || undefined,
      anchoring_action: model.anchoringAction || undefined,
      anchoring_word: model.anchoringWord || undefined,
      self_hypnosis_action: model.selfHypnosisAction || undefined,
      locale_id: model.locale!.id,
      is_test: model.isTest,
    }
  }
}